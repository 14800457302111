.gradient-text {
  font-style: normal;
  line-height: normal;
  background: linear-gradient(to right, #018ffd 5%, #070c84 100%);
  background-image: linear-gradient(113.41deg, #018ffd -3.01%, #070c84 103.26%);
  -webkit-background-clip: text; /* For Safari */
  color: transparent; /* Hide the text */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.add-photo-model{
  width: calc(100vw - 2rem);
  position: relative;
  margin-left: 4px;
}