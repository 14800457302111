.public-landing-page {
  .bid-image-container {
    background-image: url("./assets/car.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 21.5rem;
    height: 21.5rem;
    border-radius: 14px;
    position: relative;
    background-repeat: no-repeat;
    background-position: left 25% center;
    .text-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background: linear-gradient(
        359deg,
        #018ffd 38.17%,
        rgba(1, 143, 253, 0) 85.85%
      );
      color: white;
      padding: 1.5rem;
      box-sizing: border-box;
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  .landing-page-main-struck {
    flex-direction: row;
    @media (max-width: 1210px) {
      flex-direction: column;
    }
  }
}

.border-gradient {
  border-image: linear-gradient(to right, #018ffd, #070c84);
  border-image-slice: 1;
}
@keyframes slide-in-diagonal {
  0% {
    transform: translate(-0%, -0%);
    height: 100%;

    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.animate-slide-in-diagonal {
  animation: slide-in-diagonal 0.7s ease-out;
}

.input-gradient {
  border: 1px solid transparent;
  background: linear-gradient(to right, #018ffd, #070c84),
    linear-gradient(315deg, #070c84, #018ffd);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  box-shadow: none;
}

.chat-reciever {
  border-radius: 16.519px 16.519px 16.519px 0px;
  background: linear-gradient(
    265deg,
    rgba(1, 143, 253, 0.2) 22.48%,
    rgba(7, 12, 132, 0.2) 113.05%
  );
}

.chat-sender {
  border-radius: 16.519px 16.519px 0px 16.519px;
  background: linear-gradient(
    85deg,
    rgba(1, 143, 253, 0.2) 22.48%,
    rgba(7, 12, 132, 0.2) 113.05%
  );
}

.chat-modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  top: 0;
  overflow: auto; /* Enable scroll if needed */
}

.turbo-chat {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  bottom: 92;
  overflow: auto;
}

/* Styles for the chat messages container */
.chat-messages {
  height: calc(100% - 150px - 90px);
  overflow-y: scroll;
  padding: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-flow: column nowrap;
}

/* Styles for individual chat messages */
.chat-message {
  margin-bottom: 5px;
}

#chatMessages > :first-child {
  margin-top: auto;
}
.ticket-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
