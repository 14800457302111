.process-bar2 {
  .main-container2 {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
    max-width: 52rem;
  }

  .step-container2 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    position: relative;
  }

  .step-wrapper2 {
    position: relative;
    z-index: 1;
  }

  .step-style2 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .step-style-check2 {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: linear-gradient(158deg, #018ffd -15.54%, #070c84 118.17%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg-gray {
    background-color: #d9d9d9; /* or any other shade of gray */
  }

  .step-count2 {
    font-size: 19px;
    color: #f3e7f3;
  }

  .progress-bar-load2 {
    position: absolute;
    background: linear-gradient(237deg, #018ffd -1.59%, #070c84 108.23%);
    height: 4px;
    width: 0;
    top: 42%;
    left: 0;
  }
}

.input-placeholder::placeholder {
  padding-right: 25px;
}
