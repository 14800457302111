.modal-border-radius {
  border-radius: 50px;
  background: #fff;
}

.modal-close-btn-container-padding {
  padding: 40px 30px 20px 30px;
}

.modal-browse-files {
  height: 100%;
  min-height: 350px;
  background-color: #f3f3f3;
  border-radius: 20px;
  @media (max-width: 1280px) {
    min-height: 150px;
  }
}

.modal-browse-button {
  background-color: #018ffd;
  border-radius: 13.151px;
  padding: 12px;
}

.create-listing-btn {
  background: linear-gradient(99deg, #018ffd -0.78%, #070c84 106.68%);
  border-radius: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  height: 60px;
  width: 350px;
}
.gradient-background {
  background: linear-gradient(99deg, #018ffd -0.78%, #070c84 106.68%);
}

.bulk-upload-btn {
  width: 230px;
  height: 60px;
  @media (max-width: 600px) {
    width: 150px;
    height: 40px;
  }
}

.single-upload-btn {
  width: 230px;
  height: 60px;
  @media (max-width: 600px) {
    width: 150px;
    height: 40px;
  }
}
.selected-upload-type {
  background: linear-gradient(100.42deg, #018ffd -10.8%, #070c84 109.84%);
  color: #fff;
}
.default-upload-type {
  background: #d9d9d9;
  color: #018ffd;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  max-width: 1000px;
  max-height: 850px;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* overflow: auto; */
  @media (max-width: 1208px) {
    max-width: 800px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.modal-close-btn {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #999;
}

.modal-body {
  padding: 1rem;
}
