.footer-public {
        .warrenty-third-sec {
            min-height: 32rem;
            margin-top: 8rem;
            position: relative; 
            width: 100%; /* Spans the entire viewport */
            height: 100%; /* Spans the entire height of the container */
            background: 
                url("../../pages/public/membership-advantage/repair-assistance/assets/third-warrenty-img.png") rgb(23 46 158); /* Background image with fallback color */
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: -1;
            overflow: hidden;
            box-shadow: inset 0 0 0 1000px rgb(61 ,136 ,241, 0.5);
        }
    
}