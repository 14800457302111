.input-gradient-border {
  position: relative;
  background: white;
  border: 2px solid rgb(1, 143, 253);

  border-image-slice: 1;
  border-radius: 12px;
}

.input-gradient-border input {
  position: relative;
  width: 100%;
  border: none;
  outline: none;
  z-index: 0;
  background-clip: padding-box;
}
