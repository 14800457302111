.dmv-title {
  .text-transition-dvm {
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.4); // Scale the text on hover
      background: linear-gradient(
        to right,
        #018ffd 5%,
        #070c84 100%
      ); // Gradient font color
      -webkit-background-clip: text; // Apply gradient to the text only (for webkit browsers)
      -webkit-text-fill-color: transparent; // Hide the original text color (for webkit browsers)
      background-clip: text; // Apply gradient to the text only
      color: transparent; // Hide the original text color
    }
  }

  .gradiant-modal-loading {
    box-shadow: 6.076px 6.076px 4.025px 0px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    width: 100%;
    max-width: 620px;
    border-radius: 40px;
    background: linear-gradient(237deg, #018ffd -1.59%, #070c84 108.23%);
    @media (max-width: 724px) {
      max-width: 362px;
    }
  }

  /* Loader container styles */
  .loader-container {
    width: 100%;
    height: 8px;
    background-color: #d9d9d9;
    max-width: 258px;
    border-radius: 12px;
    overflow: hidden;
  }

  /* Loader progress bar styles */
  .loader-progress {
    height: 100%;
    background-color: #00dc58;
    border-radius: 16px; /* Optional: Add border radius */
    transition: width 0.3s ease-in-out; /* Optional: Add transition for smooth progress */
  }

  .confirm-btn-fin {
    border-radius: 20px;
    border: 1px solid #fff;
    background: #018ffd;
    max-width: 200px;
    width: 100%;
    box-shadow: 2px 2px 15px 0px rgba(255, 255, 255, 0.3);
  }
  .section {
    background: rgba(241, 241, 241, 0.5);
    box-shadow: 6px 6px 6.2px 0px rgba(0, 0, 0, 0.1);
    max-width: 80rem;
    width: 100%;
    min-height: 30rem;
    height: 50%;
    padding: 4rem 1.5rem;
  }
}

.process-bar {
  .main-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
    max-width: 52rem;
  }

  .step-container {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: #d9d9d9;
      height: 6px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }

    &:after {
      content: "";
      position: absolute;
      background: #d9d9d9;
      height: 4px;
      width: 0%;
      top: 50%;
      transition: 0.4s ease;
      transform: translateY(-50%);
      left: 0;
    }
  }

  .step-wrapper {
    position: relative;
    z-index: 1;
  }

  .step-style {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #d9d9d9;
    transition: 0.4s ease;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .step-style-check {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(158deg, #018ffd -15.54%, #070c84 118.17%);
    transition: 0.4s ease;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    scale: (1.5);
  }

  .step-count {
    font-size: 19px;
    color: #f3e7f3;
  }

  .step-label-container {
    position: absolute;
    top: 8rem;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .step-label {
    font-size: 19px;
    color: #4a154b;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    margin-top: 100px;
  }

  .button-style {
    border-radius: 4px;
    border: 0;
    background: #4a154b;
    color: #ffffff;
    cursor: pointer;
    padding: 8px;
    width: 90px;

    &:active {
      transform: scale(0.98);
    }

    &:disabled {
      background: #f3e7f3;
      color: #000000;
      cursor: not-allowed;
    }
  }

  .check-mark {
    font-size: 26px;
    font-weight: 600;
    color: #4a154b;
    transform: scaleX(-1) rotate(-46deg);
  }
  .progress-bar-load {
    position: absolute;
    background: linear-gradient(237deg, #018ffd -1.59%, #070c84 108.23%);
    height: 6px;
    width: 0;
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }

  @media (max-width: 724px) {
    .step-container {
      display: flex;
      flex-direction: column;
      gap: 7rem;
    }

    .step-label-container {
      left: 9rem;
      top: 1.5rem;
    }
    .step-img-texts {
      display: flex;
      flex-direction: row;
      text-wrap: nowrap;
      justify-content: space-between;
    }
    .step-container {
      flex-direction: column;
      align-items: center;
      &:before {
        content: "";
        position: absolute;
        background: #d9d9d9;
        width: 512px;
        transform: translateY(0) rotate(90deg); // Rotate for vertical orientation
        top: 0;
        left: calc(50% - 7rem);
        transform-origin: left;
      }

      &:after {
        content: "";
        position: absolute;
        background: #d9d9d9;

        width: 6px;
        transform: translateY(0) rotate(90deg); // Rotate for vertical orientation
        top: 0;
        left: calc(50% - 7rem);
        transform-origin: left;
      }
    }
    .step-style {
      margin-right: 14rem;
    }
    .step-style-check {
      margin-right: 14rem;
    }
    .step-wrapper {
      margin-left: 0;
      margin-top: 10px; // Optional: Add spacing between steps
    }

    .progress-bar-load {
      // width: 512px !important;
      transform: translateY(0) rotate(90deg); // Rotate for vertical orientation
      top: 0;
      left: calc(50% - 7rem);
      transform-origin: left;
    }
  }
}