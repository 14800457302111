.gradient-text {
  font-style: normal;
  line-height: normal;
  background: linear-gradient(to right, #018ffd 5%, #070c84 100%);
  background-image: linear-gradient(113.41deg, #018ffd -3.01%, #070c84 103.26%);
  -webkit-background-clip: text; /* For Safari */
  color: transparent; /* Hide the text */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pdf-viewer-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.pdf-viewer-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/* Base CSS for iframe */
.responsive-iframe {
  width: 100%;
  min-height: 500px; /* Minimum height for large screens */
  border: none; /* Optional: removes the border */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 767px) {
  .responsive-iframe {
    height: calc(100vh - 100px); /* Adjust height for smaller screens */
    min-height: 300px; /* New minimum height for smaller screens */
  }
}
