.left-header {
  display: flex;
  position: fixed;
  z-index: 200;
  position: absolute;
  width: 314px;
  .hamburger {
    margin-top: 54px;
    margin-left: 15px;
    padding: 16px 12px 16px 12px;
    width: 56px;
    height: 56px;
  }
  .hamburger:hover {
    cursor: pointer;
  }
  img {
    margin-top: 30px;
    margin-left: 40px;
    width: 146px;
    height: 71px;
  }
}
.navbar-main {
  display: flex;
  height: 100%;
  .navbar-header {
    position: fixed;
    background: rgba(255, 255, 255, 0.5); /* Transparent white background */
    backdrop-filter: blur(10px); /* Blur effect */
    flex: 1;
    padding-left: 20px;
    padding-right: 320px;
    height: 121px;
    // right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Metropolis;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 49;
    .header-search {
      max-width: 440.322px;
      width: 100%;
      height: 65.295px;
      border-radius: 12.619px;
      border: 2.4px solid #018ffd;
      background: #fff;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        margin-left: 21px;
      }
      input {
        border: none;
        overflow: hidden;
        outline: none;
        color: #263238;
        font-family: Metropolis;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 20px;
      }
    }
    .header-btn {
      display: flex;
      flex-direction: row;
      // width: 100%;
      // max-width: 386px;
      align-items: center;
      justify-content: space-between;
      right: 0;
      .header-notification,
      .header-help {
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          margin-left: 8px;
          color: #313131;
          font-size: 14px;
        }
      }
      .header-notification:hover,
      .header-help:hover {
        cursor: pointer;
      }
      .header-line {
        width: 1px;
        height: 44px;
        background: #313131;
      }
      .header-name {
        color: #313131;
        font-family: Metropolis;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .navbar-header-mobile {
    position: fixed;
    background: #f1f1f1;
    backdrop-filter: blur(10px); /* Blur effect */
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
    height: 121px;
    // right: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Metropolis;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 100;
    .header-search {
      border-radius: 12.619px;
      padding: 8px;
      background: #fff;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-left: 12px;
      }
      input {
        border: none;
        outline: none;
        color: #263238;
        font-family: Metropolis;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-size: 14px;
      }
    }
    .header-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 156px;
      right: 0;
      .header-notification,
      .header-help {
        display: flex;
        flex-direction: row;
        align-items: center;
        div {
          margin-left: 8px;
          color: #313131;
          font-size: 14px;
        }
      }
      .header-notification:hover,
      .header-help:hover {
        cursor: pointer;
      }
      .header-line {
        width: 1px;
        height: 44px;
        background: #313131;
      }
      .header-name {
        color: #313131;
        font-family: Metropolis;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
  .navbar-left {
    position: relative;
    // z-index: 100;
    font-family: Metropolis;
    width: 314px;
    background: rgba(255, 255, 255, 0);
    box-shadow: 3px 0px 10px 0px rgba(0, 0, 0, 0.1);
    height: fit-content;

    .navbar-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 220px;
      .content-detail {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 68px;
        padding: 19px 0px 19px 23px;
        align-items: center;
        gap: 16px;
        opacity: 0.7;
        .dorp-down {
          width: 17.46px;
          height: 10.14px;
          margin-left: 110px;
        }
        img {
          width: 30px;
          height: 30px;
        }
        .detail-text {
          color: #131313;
          font-family: Metropolis;
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .content-detail:hover {
        cursor: pointer;
      }
      .content-line {
        display: flex;
        width: 285px;
        height: 1px;
        background: rgba(104, 104, 104, 0.5);
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .navbar-left-mobile {
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    font-family: Metropolis;

    width: 214px;
    box-shadow: 3px 0px 10px 0px rgba(0, 0, 0, 0.1);
    height: 100vh;

    .navbar-content {
      display: flex;
      flex-direction: column;
      margin-top: 58px;
      align-items: center;
      .content-detail {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 0px;
        padding: 19px 0px 19px 23px;
        align-items: center;
        gap: 16px;
        opacity: 0.7;
        .dorp-down {
          width: 17.46px;
          height: 10.14px;
          margin-left: 110px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        .detail-text {
          color: #131313;
          font-family: Metropolis;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .content-detail:hover {
        cursor: pointer;
      }
      .content-line {
        display: flex;
        width: 216px;
        height: 1px;
        background: rgba(104, 104, 104, 0.5);
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
  .navbar-right {
    width: -webkit-fill-available;
  }
  .navbar-right-full {
    width: -webkit-fill-available;
    max-height: calc(100vh - 123px);
    height: 100%;
  }
}
