.repair-assistance{
    .main-warrenty-page{
        height: 100%;
        min-height: calc(100vh - 80px);
    }
    .main-side-img {
        height: 28rem;
        max-width: 50rem;
        width: calc(100% - 8rem);
        background-image: url("./assets/Rectangle_w.svg");
        background-size: cover; /* Cover ensures that the background image covers the entire div */
        background-position-x: right;
        background-position-y: top; /* Position the background image in the center */
        background-repeat: no-repeat;
        @media (max-width: 1600px) {
            // width: calc(100% - 18rem);
            height: 21rem;
        }
       
        @media (max-width: 1278px) {
            width: calc(100% - 18rem);
            height: 32rem;
            
        }
        @media (max-width: 781px) {
            width: calc(100% - 8rem);
       
        }
        @media (max-width: 524px) {
            width: calc(100% - 6rem);
            height: 19rem;
        }

       
    }
    
    .second-warrenty-img {
        // background-image: url("./assets/Rectangle_w.svg");
        position: relative;
        min-height: 32rem;
        left: -8rem;
        background: linear-gradient(151deg, #018FFD 3.85%, #070C84 94.89%);
        background-size: cover; /* Cover ensures that the background image covers the entire div */
        background-position-x: right;
        background-position-y: top; /* Position the background image in the center */
        background-repeat: no-repeat;
        @media (max-width: 524px) {
            left: -2rem;
        }
    }

    // .warrenty-third-sec {
    //     min-height: 32rem;
    //     margin-top: 8rem;
    //     position: relative; /* Ensure relative positioning for containing element */

    //   }
      
    //   .warrenty-third-sec::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: calc(100vw - 24px); /* Spans the entire viewport */
    //     height: 100%; /* Spans the entire height of the container */
    //     background: 
    //         url("./assets/third-warrenty-img.png") rgb(23 46 158); /* Background image with fallback color */
    //     background-position: center;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     z-index: -1;
    //     overflow: hidden;
    //     box-shadow: inset 0 0 0 1000px rgb(61 ,136 ,241, 0.5);
    //      @media (max-width: 781px) {
    //         width: calc(100vw - 24px); /* Spans the entire viewport */
    //     }
    // }
    
    
}