.border-gradient-to-r {
  position: relative;
}

.border-gradient-to-r::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to right, #018ffd, #070c84);
  border-radius: inherit;
}
