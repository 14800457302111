.enter-vin {
  .gradiant-box-vin {
    border-radius: 37.975px;
    background: linear-gradient(237deg, #018ffd 0%, #070c84 100%);
    box-shadow: 6.076px 6.076px 4.025px 0px rgba(0, 0, 0, 0.1);
    width: 100%;

    // height: 100%;
  }

  .gradiant-modal-loading {
    box-shadow: 6.076px 6.076px 4.025px 0px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    width: 100%;
    max-width: 580px;
    border-radius: 40px;
    background: linear-gradient(237deg, #018ffd -1.59%, #070c84 108.23%);
  }

  /* Loader container styles */
  .loader-container {
    width: 100%;
    height: 20px;
    background-color: white;
    max-width: 258px;
    border-radius: 16px;
    overflow: hidden;
    border-width: 4px;
  }

  /* Loader progress bar styles */
  .loader-progress {
    height: 100%;
    background-color: #018ffd;
    border-radius: 16px; /* Optional: Add border radius */
    transition: width 0.3s ease-in-out; /* Optional: Add transition for smooth progress */
  }

  .confirm-btn-fin {
    border: 1px solid #fff;
    background: #018ffd;

    box-shadow: 2px 2px 15px 0px rgba(255, 255, 255, 0.3);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}
