.input {
  font-family: "Metropolis";
}

.input-control {
  border-radius: 20px;
  border: 1.206px solid #018ffd;
  background: #fff;
  min-height: 56px;
}
.input-control:where(
    .css-dev-only-do-not-override-1rqnfsa
  ).ant-input-affix-wrapper
  > input.ant-input::-webkit-input-placeholder {
  text-indent: -20px;
  color: #888888;
  font-family: "Metropolis";
}
.input-control:where(
    .css-dev-only-do-not-override-1rqnfsa
  ).ant-input-affix-wrapper
  > input.ant-input::-moz-input-placeholder {
  text-indent: -20px;
  color: #888888;
  font-family: "Metropolis";
}
.input-control:where(
    .css-dev-only-do-not-override-1rqnfsa
  ).ant-input-affix-wrapper
  > input.ant-input::-ms-input-placeholder {
  text-indent: -20px;
  color: #888888;
  font-family: "Metropolis";
}

.custom-button {
  border-radius: 20px;
  min-height: 56px;
  width: 100%;
  background: linear-gradient(95deg, #018ffd -1.48%, #070c84 102.77%);
  color: #fff;
}

.custom-button:where(.css-dev-only-do-not-override-1rqnfsa).ant-btn > span {
  font-family: "Metropolis";
}

button.ant-btn.custom-button:hover,
button.ant-btn.custom-button:not(:disabled):not(.ant-btn-disabled):hover {
  background: linear-gradient(95deg, #070c84 -1.48%, #018ffd 102.77%);
  color: #fff;
}
.upload-button {
  background: linear-gradient(95deg, #018ffd -1.48%, #070c84 102.77%);
  color: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  font-size: 25px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-image {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}
.profile-image .upload-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
