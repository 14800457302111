.selected {
  background: linear-gradient(100.42deg, #018ffd -10.8%, #070c84 109.84%);
  color: #fff;
  height: 54px;
}
.default {
  background: #d9d9d9;
  color: #070c84;
  height: 54px;
}

tbody tr td {
  max-width: 200px;
}

.input-container {
  position: relative;
}

.input-container input::placeholder {
  padding-left: 40px;
}
