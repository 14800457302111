.gradient-text {
  background: linear-gradient(103.74deg, #018ffd -10.04%, #070c84 107.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pdf-thumbnail{
  width: 100%;
  position: relative;
  .rpv-thumbnail__cover-image{
    width: 100%;
    
  }
}