.card-tier-details {
  height: 600px;
  // width: 350px;
  background: linear-gradient(
    167.78deg,
    rgba(1, 143, 253, 0.5) -20.7%,
    rgba(1, 143, 253, 0.5) 84.91%
  );
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &.hover-scale {
    transform: scale(1.1);
  }
  // @media (min-width: 1665px) and (max-width: 1770px) {
  //   width: 330px;
  // }
  // @media (min-width: 1400px) and (max-width: 1665px) {
  //   width: 310px;
  // }
  // @media (min-width: 1300px) and (max-width: 1400px) {
  //   width: 320px;
  // }
  // @media (min-width: 1279px) and (max-width: 1300px) {
  //   width: 300px;
  // }
}

.card-content {
  text-align: center;
}

.feature-list {
  margin-top: 120px;
  text-align: left;
}

.feature {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.card {
  background: linear-gradient(
    167.78deg,
    rgba(1, 143, 253, 0.5) -20.7%,
    rgba(1, 143, 253, 0.5) 84.91%
  );
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.hover-scale {
  transition: transform 0.3s ease-in-out;
}
.hover-scale:hover {
  transform: scale(1.1);
}