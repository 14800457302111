.chatsuppbtn {
  color: #fff;
  /* background: linear-gradient(96deg, #018ffd -2.11%, #070c84 112.91%); */
  background: #018ffd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blur {
  filter: blur(5px); /* Adjust the blur intensity as needed */
}
.chatsuppbtn:hover span {
  transform: scale(1.1);
}

.chatsuppbtn span {
  transition: transform 0.25s 0s;
  transform: scale(1);
  transform-origin: center;
}

.register-left {
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 579px;
  text-align: center;
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0px 0px 20px;
  font-family: Metropolis;
}

.logo-svg {
  margin-top: 57.5px;
  width: 150px;
  height: 70px;
}
.register-header {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.register-description {
  margin-top: 37.72px;
  font-size: 20px;
  line-height: 20px;
  width: 526px;
}
.register-description1 {
  font-size: 20px;
  line-height: 20px;
  width: 526px;
}
.register-cotact {
  margin-top: 134.72px;
  font-size: 16px;
  line-height: 16px;
}

@media screen and (min-width: 768px) {
  .register-right {
    display: flex;
    flex-direction: column;
    border-radius: 0px 20px 20px 0px;
    border: 1px solid #dbdbdb;
    background: rgba(241, 241, 241, 0.5);
    box-shadow: 3px 5px 9.65px 0px rgba(0, 0, 0, 0.1);
    color: #313131;
    font-family: Metropolis;
  }
}

@media screen and (max-width: 1158px) {
  .register-left {
    border-radius: 8px;
  }
}

.fade-with-gradient {
  position: relative;
  display: inline-block;
}

.fade-with-gradient::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 12px 0px 0px 12px;
  height: 100%;
  background: linear-gradient(
    141deg,
    rgba(1, 143, 253, 0.5) 9.6%,
    rgba(7, 12, 132, 0.5) 97.71%
  ); /* Adjust the colors and transparency as needed */
}

.signUpBtn {
  background: linear-gradient(93deg, #018ffd -5.46%, #070c84 102.58%);
  color: #fff;
  border-radius: 20px;
}

.signUpBtn:hover {
  background: linear-gradient(93deg, #29a1fc -5.46%, #252ab7 102.58%);
  color: #fff;
  border-radius: 20px;
}

.signUpContainer {
  background: rgba(241, 241, 241, 0.5);
  box-shadow: 9.65px 14.475px 9.65px 0px rgba(0, 0, 0, 0.1);
}

.captcha > div > div {
  width: 250px !important;
  height: 50px !important;
}
.signupPlaceholder {
  border: double 1px transparent;
  border-radius: 12px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #018ffd, #070c84);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  line-height: 1.2;
}

.signupPlaceholderErr {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  border-radius: 16px;
  background-image: none;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  /* line-height: 1.2; */
}

.signupPlaceholder::-webkit-input-placeholder {
  color: #8c8c8c;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .signUpContainer {
    box-shadow: none;
    border: none;
  }
}

.grad {
  /* background: linear-gradient(96deg, #018ffd -1.38%, #070c84 102.61%); */
  background: #018ffd;
}

.chat-reciever {
  border-radius: 16.519px 16.519px 16.519px 0px;
  background: linear-gradient(
    265deg,
    rgba(1, 143, 253, 0.2) 22.48%,
    rgba(7, 12, 132, 0.2) 113.05%
  );
}

.chat-sender {
  border-radius: 16.519px 16.519px 0px 16.519px;
  background: linear-gradient(
    85deg,
    rgba(1, 143, 253, 0.2) 22.48%,
    rgba(7, 12, 132, 0.2) 113.05%
  );
}

.chat-modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  top: 0;
  overflow: auto; /* Enable scroll if needed */
}

.turbo-chat {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  bottom: 92;
  overflow: auto;
}

/* Styles for the chat messages container */
.chat-messages {
  height: calc(100% - 150px - 90px);
  overflow-y: scroll;
  padding: 10px;
  margin-bottom: 25px;
  display: flex;
  flex-flow: column nowrap;
}

/* Styles for individual chat messages */
.chat-message {
  margin-bottom: 5px;
}

#chatMessages > :first-child {
  margin-top: auto;
}
.ticket-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
