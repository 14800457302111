/* @import '~@fontsource/metropolis/index.css'; */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Metropolis", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .br500 {
    @apply border-2 border-red-500;
  }
}

@font-face {
  font-family: "Metropolis-Bold";
  src: local("Metropolis"),
    url(../public/assets/fonts/Metropolis-Bold.otf)
      format("truetype");
}

@font-face {
  font-family: "Metropolis-SemiBold";
  src: local("Metropolis"),
    url(../public/assets/fonts/Metropolis-SemiBold.otf)
      format("truetype");
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url(../public/assets/fonts/Metropolis-Regular.otf)
      format("truetype");
}

.gradient-text {
  font-style: normal;
  line-height: normal;
  background: linear-gradient(to right, #018ffd 5%, #070c84 100%);
  background-image: linear-gradient(113.41deg, #018ffd -3.01%, #070c84 103.26%);
  -webkit-background-clip: text; /* For Safari */
  color: transparent; /* Hide the text */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* 
#embedded-messaging {
  display: none;
} */

.slide-right {
  animation: 1s slide-right;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}

.embeddedMessagingConversationButtonWrapper {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}

.minimizedButtonWrapper {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}

.embeddedMessagingConversationButton
  .embeddedMessagingConversationButtonLoaded
  .no-hover {
  display: none !important;
  @media (max-width: 767px) {
    display: block !important;
  }
}

.embedded-messaging iframe.to-position {
  top: 130px !important;
  @media (max-width: 767px) {
    top: 50px !important;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%) !important;
    opacity: 0 !important;
  }
  to {
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
}
