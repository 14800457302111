.listing-btn {
  background: linear-gradient(180deg, #018ffd 0%, #070c84 100%);
}

.searchPlaceholder {
  border: double 1px transparent;
  border-radius: 10px;
  background-image: linear-gradient(#efefef, #efefef),
    radial-gradient(circle at top left, #018ffd, #070c84);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  line-height: 1.2;
}

.searchPlaceholder::-webkit-input-placeholder {
  color: #313131;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  height: 100%;
  /* background: #efefef; */
}

::placeholder {
  line-height: 1.4;
  padding-left: 30px;
}

/* .car-description {
  border-radius: 0px 0px 19.763px 19.763px;
  background: linear-gradient(194deg, #018ffd 10.11%, #070c84 93.71%);
}

.car-description:hover {
  background: linear-gradient(14deg, #018ffd 10.11%, #070c84 93.71%);
} */

.car-description {
  background: linear-gradient(194deg, #018ffd 10.11%, #070c84 93.71%);
  transition: background-color 0.3s 0s;
}
/* 
.car-description:hover {
  background: linear-gradient(194deg, #070c84 10.11%, #018ffd 93.71%);
} */

.preview-status {
  width: 70%;
  background: linear-gradient(99deg, #018ffd -0.78%, #070c84 106.68%);
}

.modal-preview-files {
  border-radius: 20px;
  background-color: #f3f3f3;
  margin-bottom: 12px;
}

.loader-line-manage-list-img {
  margin-top: auto;
  position: relative;
  overflow: hidden;
    background: linear-gradient(99deg, #018ffd -0.78%, #070c84 106.68%);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line-manage-list-img:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 100%;
  width: 40%;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }
  50% {
      left: 20%;
      width: 80%;
  }
  100% {
      left: 100%;
      width: 100%;
  }
}