.bhphf {
    .triangle-gradient {
        // width: 300px; /* Set your desired width */
        height: 32rem;
        background-image: url('./assets/triangle-gradient-svg.svg'); /* Replace with the actual path to your SVG */
        background-size: cover; /* Adjust as needed (cover, contain, etc.) */
        background-position: center;
        /* Other styling properties (e.g., padding, margin, etc.) */
      }
      
}