.notification-container {
  width: 390px;
  /* max-width: 600px; Adjust as needed */
  overflow-y: auto;
  @media (max-width: 524px) {
    width: calc(100vw - 2rem);
    position: relative;
    margin-left: 4px;
  //   width: calc(98vw - 2rem - 1.5vh);
  // }
  // @media (max-width: 320px) {
  //   width: calc(98.5vw - 3rem - 1.2vh);
  }
}


.notification {
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  /* border-radius: 8px; */
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.avatar {
  min-width: 60px; /* Adjust as needed */
  min-height: 70px; /* Adjust as needed */
  overflow: hidden;
  border-radius: 50%;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notification-content {
  margin-left: 10px;
  width: 100%;
}

.notification-title {
  margin: 0;
  font-size: 15px;
}

.notification-message {
  margin: 5px 0 0;
  font-size: 10px;
  line-height: 12px;
  color: #666;
}
