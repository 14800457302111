.tools-menu-content-details {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 20%;

  .btn-detail-text {
    color: #131313;
    font-family: Metropolis;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    opacity: 0.7;
  }
}
