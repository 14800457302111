.card {
  height: 600px;
  width: 290px;
  background: linear-gradient(
    167.78deg,
    rgba(1, 143, 253, 0.5) -20.7%,
    rgba(1, 143, 253, 0.5) 84.91%
  );
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  @media (min-width: 1275px) and (max-width: 1770px) {
    width: 245px;
    height: 500px;
  }

  @media (min-width: 1000px) and (max-width: 1275px) {
    width: 250px;
    height: 600px;
  }
}

.hover-scale {
  transform: scale(1.1);
  @media (max-width: 1200px) {
    transform: scale(1);
  }
  @media (max-width: 1007px) {
    transform: scale(1.1);
  }
}

.card-content {
  text-align: center;
}

.feature-list-pricing {
  text-align: left;
  @media (min-width: 1275px) and (max-width: 1770px) {
    margin-top: 50px;
  }
  margin-top: 120px;
}

.feature {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
